/**
 * Mobile only
 **/


@include media-breakpoint-down(md) {
	#menu-entries{
		display: none !important;
	
		&.collapse{
			display: none !important;
		}

		&.collapse.in{
			display: inherit !important;
		}
	}
}

.navbar-nav{
	margin-left: 60px !important;
}

@include media-breakpoint-down(md) {
	.navbar{
		min-height: 80px !important;
	}

	.navbar-brand{
		position: absolute;
		top: 0;
		left: 10px;
	}

	.navbar-nav{
		padding-left: 0;
    	margin-left: 15px !important;
    	margin-top: 6rem !important;
	}

	.navbar-toggler{
    	position: absolute;
    	right: 45px;
    	top: 25px;
    }

    .search-widget{
    	display: none;
    }
}

/**
 * Navbar
 **/

.navbar-nav{
	float: right !important;
}

.navbar-brand > img {
    display: block;
    max-width: 170px;
    border-radius: 0;
}

.navbar-brand{
	height: auto !important;
	padding-top: 0 !important;
}

#nav-main.bg-light{
	background-color: transparent !important;
}

/**
 * Links
 **/

.navbar-light .navbar-nav .nav-link{
	&:hover,
	&:focus{
		background: none !important;
	}
}

.navbar-light .navbar-nav .nav-link {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: 40px;
    margin-right: 15px;
}

.navbar-light .navbar-nav .dropdown-menu .nav-link{
	margin-left: 15px;
	margin-bottom: 5px;

	&:after{
		display: none;
	}
}	

.navbar-light .navbar-nav .dropdown-menu{
	/*border: 1px solid #F2F2F2;*/
	border: none;
	padding: 1rem;
	margin-top: 15px;
}

.nav-link{
 	color: $secondary-color !important;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: .5px;
	text-transform: uppercase;
	transition: all .4s ease-in-out;

	&:after{
		content: '';
		position: absolute;
		height: 2px;
		background-color: $secondary-color;
		width: 0%;
		transition: all .4s ease-in-out;
		left: 0;
		bottom: -3px;
	}

	&:hover{
		color: $primary-color !important;
		text-decoration: none;

		&:after{
			width: 100%;
			background-color: $primary-color;
		}
	}
}

.dropdown-toggle::after{
	border-top: none !important;
    border-right: none !important;
    border-bottom: 0;
    border-left: none !important;
}

