/**
 * Front
 **/


/**
 * Services
 **/

 .services-inner-wrapper{
    background-color: #F7F7F7;
    border-radius: 90px;
    padding: 4rem;

    @include media-breakpoint-down(sm) {
    	background-color: transparent;
    	padding: 0;
    }
 }

 .service{
 	margin-bottom: 2rem;

 	h3{
 		margin-top: 3rem;
 	}

 	.image-wrapper{
 		position: relative;
 	}

 	.image-overlay{
	 	position: absolute;
	    bottom: 10px;
	    right: 25px;
	}
 }

 .services-small h3{
 	margin-left: 25px;
 }


@include media-breakpoint-down(sm) {
	.intro h2{
		text-align: left;
	}
}