/**
 * Typography
 **/

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
	font-family: $primary-font;
}

 h1{

 }

 h2,
 h3,
 h4,
 h5{
 	color: $primary-color;
 	font-weight: 700;
 }

 p{
 	color: $secondary-color;
 }


 /**
  * Buttons
  **/

  .btn{
  	color: $white;
  	text-transform: uppercase;
  	transition: all .3s ease-in-out;
  	border-radius: 12px !important;
  	padding: 12px 18px !important;
    font-size: 12px !important;
    font-weight: 700 !important;

    &:focus{
    	outline: 0 !important;
    }

  	&--primary{
	  	background-color: $primary-color;

	  	&:hover{
	  		background-color: $secondary-color;
	  		color: $white !important;
	  		padding-right: 30px !important;
	  	}
  	}

  	&--secondary{
	  	background-color: $secondary-color;

	  	&:hover{
	  		background-color: $secondary-color;
	  		color: $white !important;
	  		padding-right: 30px !important;
	  	}
  	}
  }