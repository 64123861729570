/**
 * Global
 **/

.block{
	margin-top: 4rem;
	margin-bottom: 4rem;
}

img{
	max-width: 100%;
	border-radius: 25px;
}