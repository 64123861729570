/**
 * Global variables
 **/

$image_path: 'images/';


/**
 * Fonts
 **/
$primary-font: 'Roboto', sans-serif;

/**
 * Colors
 **/

$primary-color: #1BB1A9;
$secondary-color: #182267;

$white: #FFFFFF;
$black: #000000;