/**
 * Header
 **/
.header-image{
	height: 400px;
	position: relative;
	background-position: center;
	background-size: cover;
}

.header-wrap{
	background-color: rgba($secondary-color, .85); 
	position: absolute;
	bottom: 60px;
	padding: 1rem;
	padding-right: 1.5rem;
	border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;

	h1{
		color: $white;
		font-weight: 700;
		margin-right: 150px;
		margin-left: 30px;
	}

	.btn{
		background-image: url('images/icon-button-white.svg');
		background-repeat: no-repeat;
    	background-position: 17px 10px;
    	background-size: 10px;

    	&--primary{
    		padding-left: 45px !important;
    	}
    }	

    &.subpage{
    	margin-left: 15px;
    	margin-right: 15px;
    	border-radius: 25px;
    }

    @include media-breakpoint-down(sm) {
    	h1{
    		font-size: 23px;
    		margin-right: 25px;
    	}
    }
}


/**
 * CTA
 **/

.cta-top{
	.cta-tel,
	.cta-afspraak{
		float: right;

		p,
		a{
			font-size: 12px;
		}
	}

	.cta-afspraak{
		margin-right: 30px;

		p{
			line-height: 30px;
			margin-bottom: 0;	
		}

		a{
	 		color: $primary-color;
	 		font-weight: 700;
	 		letter-spacing: .5px;
	 		text-transform: uppercase; 	
	 		transition: all .4s ease-in-out;		

	 		&:hover{
	 			color: $secondary-color;
	 			text-decoration: none;

	 			&:after{
	 				width: 100%;
	 				background-color: $primary-color;
	 			}
	 		}
	 	}	
	}

	.cta-tel{
		background-color: $primary-color;
		margin-bottom: 0;
		margin-right: 45px;
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;

		background-image: url(images/icon-tel.svg);
		padding-left: 30px;
		background-repeat: no-repeat;
    	background-position: 15px 8px;

		p{
			margin-left: 15px;
			margin-right: 15px;			
		}

		p,
		a{
			line-height: 30px;
			margin-bottom: 0;
			color: $white;
			text-transform: uppercase;	
			font-weight: 700;
		}

		a{
			&:hover{
				text-decoration: none;
			}
		}
	}
}