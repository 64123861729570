/**
 * Footer
 **/

 footer{
 	background-color: $secondary-color;
 	padding-top: 4rem;
 	padding-bottom: 4rem;

 	h5{
 		margin-bottom: 1.5rem;
 	}

 	ul{
 		list-style-type: none;
 		margin-left: 0;
 		padding-left: 0;
 	}

 	li,
 	a{
 		color: $white;
 		font-size: 14px;
 		font-weight: 700;
 		letter-spacing: .5px;
 		text-transform: uppercase;
 	}

 	li{
 		margin-bottom: 5px;
 	}

 	a{
 		transition: all .3s ease-in-out;
 		position: relative;

 		&:after{
 			content: '';
 			position: absolute;
 			height: 2px;
 			background-color: $secondary-color;
 			width: 0%;
 			transition: all .4s ease-in-out;
 			left: 0;
 			bottom: -3px;
 		}

 		&:hover{
 			color: $primary-color;
 			text-decoration: none;

 			&:after{
 				width: 100%;
 				background-color: $primary-color;
 			}
 		}
 	}

 	.social-media{
 		img{
 			border-radius: 0;
 			margin-right: 10px;
 		}

 		a{
 			&:after{
 				display: none;
 			}
 		}
 	}
 }

 footer.copyright-container{
 	background-color: $white;
	padding-top: 1rem;
 	padding-bottom: 1rem;

 	li,
 	a{
 		color: $primary-color;
 		margin-right: 15px;
 	}

 	p{
 		color: $secondary-color;
 		font-size: 14px;
 		font-weight: 700;
 		letter-spacing: .5px;
 		text-transform: uppercase; 		
 	}
 }

