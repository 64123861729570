/**
 * Subpage
 **/
 .subpage-inner-wrapper{
    border-radius: 90px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 4rem;
    padding-bottom: 6.5rem;

    h2{
   		font-size: 28px;
   		margin-bottom: 2.5rem; 		
    }

    h3{
    	color: $secondary-color;
    	font-size: 20px;
    	text-transform: uppercase;
    	letter-spacing: .5px;
    	margin-bottom: 1rem;
    	margin-top: 2rem;
    }

    p{
    	margin-bottom: 10px;
    }

    &.cta{
    	background-color: transparent;
    	border-radius: 0;
    	padding-top: 0;
    	padding-bottom: 0;
    }


    @include media-breakpoint-up(md) {
    	background-color: #F7F7F7;
    }
 }

/**
* Carousel
**/

.subpage-slider{
	margin-top: 2.5rem;

	&.owl-theme .owl-dots .owl-dot span{
		background: rgba($primary-color, .3)
	}

	&.owl-theme .owl-dots .owl-dot.active span{
		background: $primary-color;
	}
}

/**
 * Call to action
 **/

.call-to-action{
	&__inner{
		background-color: $primary-color;
		margin-top: -75px;
		margin-bottom: 4rem;
		padding: 1rem;
		padding-right: 1.5rem;
		border-radius: 25px;

		h1{
			color: $white;
			font-weight: 700;
			margin-right: 150px;
			margin-left: 30px;
		}
	}	

	.btn{
		background-image: url('images/icon-button.svg');
		background-repeat: no-repeat;
    	background-position: 17px 10px;
    	background-size: 10px;
    	font-size: 15px !important;

    	&--secondary{
    		padding-left: 45px !important;
    	}
	}
}


